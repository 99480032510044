import React from 'react'
import Layout from "../components/layout"
import Header from '../components/header'
import styles from "./contact.module.css"
// import MyResume from "../../public/static/PatrickOHern-Resume.pdf"
import MyResume from "../../static/PatrickOHern-Resume.pdf"


export default function ContactPage() {
  return (
    <Layout>
      <div>
        {/* <Header headerText="Contact Me" /> */}
        <h3 className={styles.pat}>
          Here are the best ways to reach me or to see my resume and other
          social sites. I look forward to connecting!
        </h3>
        <div className={styles.footer} style={{ marginBottom: "10%", marginTop:'3%' }}>
          <a
            href="https://github.com/pohern"
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* <p>GitHub</p> */}
            <img
              src="https://cdn.jsdelivr.net/npm/simple-icons@3.0.1/icons/github.svg"
              style={{ width: "50px" }}
              alt="GitHub"
            ></img>
          </a>
          <a
            href="https://www.linkedin.com/in/patrick-ohern//"
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* <p>Let's Connect On LinkedIn</p> */}
            <img
              src="https://cdn.jsdelivr.net/npm/simple-icons@3.0.1/icons/linkedin.svg"
              style={{ width: "50px" }}
              alt="LinkedIn"
            ></img>
          </a>
        </div>
        {/* <div className={styles.email}>
          <form
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            action='/thanks'
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <p className={styles.emailText} style={{textDecoration:'underline'}}>Send Me An Email</p>
            <p className={styles.fields}>
              <label>
                <input
                  type="text"
                  placeholder="Your Name"
                  name="name"
                  style={{ textAlign: "center" }}
                />
              </label>
            </p>
            <p className={styles.fields}>
              <label>
                <input
                  type="email"
                  placeholder="Your Email"
                  name="email"
                  style={{ textAlign: "center" }}
                />
              </label>
            </p>
            <p className={styles.fields}>
              <label>
                <textarea
                  name="message"
                  placeholder="Your Message"
                  style={{ textAlign: "center" }}
                ></textarea>
              </label>
            </p>
            <p className={styles.fields}>
              <button type="submit">Send</button>
            </p>
          </form>
        </div> */}
      </div>
    </Layout>
  )
}
 


